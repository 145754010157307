import './font-awesome-css'; // must come before main.scss
import '../sass/main.scss';
import './md-section';
// import './twitch-emotes';
import './fb-link';
import './widgets/gender-svgs';
import './email-link';
import $ from 'jquery';
import initNav from './nav';

let match = /^(\/?(?:[^\/]\/)*)index.html$/i.exec(window.location.pathname);
if (match) {
  window.history.replaceState({}, document.title, match[1]);
}

$('.alert [data-fix-links] a').addClass('alert-link');
initNav();

function resizeHeader() {
  const $header = $('#title-header');
  $header.css('font-size', '');
  if ($header.width() > $(window).width() - 30) {
    $header.fitText(0.75);
    $(window).off('resize.fittext orientationchange.fittext');
  }
}

let scrolled = false;
document.addEventListener('scroll', () => scrolled = true);

$(window).on('resize', resizeHeader);

function onFbIframeLoad(event) {
  /** @type {HTMLIFrameElement} */
  const el = this || event.target;
  if (!scrolled) {
    el.scrollIntoView({ behavior: 'auto'});
  }

  $(el).closest('div.fb-post.loading').removeClass('loading');
}

const observer = new MutationObserver((mutations) => {
  for (const mutation of mutations) {
    Array.from(mutation.addedNodes)
      .filter(node => node.nodeType === 1 && node.matches('div.fb-post.loading iframe'))
      .forEach(i => i.addEventListener('load', onFbIframeLoad));
  }
});

observer.observe(document.body, { subtree: true, childList: true });

$(() => {
  $('div.fb-post.loading iframe').on('load', onFbIframeLoad);
  setTimeout(() =>
    $('div.fb-post.loading')
      .filter(function () { return !$(this).has('iframe').length; })
      .each(function () {
        $(this).append(
          $('<a>')
            .addClass('fb-link')
            .attr('href', $(this).data('href'))
            .attr('target', '_blank')
            .text("Link to FB post in case the embed doesn't work")
        )}),
    1000);

  resizeHeader();
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="tooltip"]').tooltip();
});