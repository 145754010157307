import $ from 'jquery';

$(() => {
    const $email = $('#email-link'),
        $emailText = $email.find('.contact-text'),
        mailtoCodes = [ 109, 97, 105, 108, 116, 111, 58 ],
        charCodes = [ 106, 111, 114, 100, 97, 110, 64, 100, 120, 100, 116, 46, 108, 105, 102, 101 ],
        encodedAddress = charCodes.map(c => `%${c.toString(16).toUpperCase()}`).join(''),
        encodedText = charCodes.map(c => `&#${c};`).join('');
    $email.attr('href', `${mailtoCodes.map(c => String.fromCharCode(c)).join('')}${encodedAddress}`);
    $emailText.html(encodedText);
});