import Cookies from 'js-cookie';
import moment from 'moment';

const lastVisitCookie = 'last-visit',
    nextLastVisitCookie = 'next-last-visit',
    page = window.location.pathname,
    nextLastVisitDates = JSON.parse(Cookies.get(nextLastVisitCookie) || '{}'),
    lastVisitDates = JSON.parse(Cookies.get(lastVisitCookie) || '{}');

export const lastVisits = {};

for (const [path, visit] of Object.entries(lastVisitDates)) {
    lastVisits[path] = new Date(visit);
}

const today = moment().startOf('day');
for (const [path, visit] of Object.entries(nextLastVisitDates)) {
    const date = new Date(visit);
    if (date < today) {
        lastVisits[path] = date;
    }
}

export const lastPageVisit = lastVisits[page];

Cookies.set(lastVisitCookie, JSON.stringify(lastVisits));
nextLastVisitDates[page] = today.endOf('day');
Cookies.set(nextLastVisitCookie, JSON.stringify(nextLastVisitDates));