import $ from 'jquery';
import { FbLinkFallBackURLTTL } from './constants';
import { getFBAppUrl, isMobile } from './helpers';

if (isMobile) {
    $('.facebook-link').on('click', function () {
        let t = setTimeout(() => window.location = this.href, FbLinkFallBackURLTTL);

        // best effort to prevent fallback from triggering if the fb app is opened
        $(window).one('blur', () => clearTimeout(t));
        window.location = getFBAppUrl(this.href);

        return false;
    });
}