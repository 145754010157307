import $ from 'jquery';

const ScrollSpyOffset = 12;
const CollapseTransitionTime = 350; // 350ms == $transition-collapse

const $bodyHtml = $('body, html');
const $body = $('body');
const $navWrapper = $('#navbar-wrapper');
const $nav = $('#nav-items');
// const $content = $('.md-content');

let bodyScrollTop = null;

function setScrollOnToggle() {
    let $tog = $('.navbar-toggler');

    $tog.on('click', (e, clickedNavItem) => {
        if ($body.is('.noscroll') && !clickedNavItem && bodyScrollTop !== null) {
            $bodyHtml.animate({
                scrollTop: bodyScrollTop
            }, CollapseTransitionTime);
            bodyScrollTop = null;
        } else if (window.pageYOffset < $navWrapper.offset().top) {
            bodyScrollTop = window.pageYOffset;
            $bodyHtml.animate({
                scrollTop: $navWrapper.offset().top + 1
            }, CollapseTransitionTime);
        } else if (clickedNavItem) {
            bodyScrollTop = null;
        }

        $body.toggleClass('noscroll');
    });

    $nav.on('click', 'a.nav-link', () => {
        if ($tog.is(':visible')) {
            $tog.trigger('click', [true]);
        }
    });
}

function enableScrollSpy(enable = true) {
    if (enable) {
        $body.scrollspy({ target: '#nav-items', offset: ScrollSpyOffset });
    } else {
        $body.scrollspy('dispose');
    }
}

// stolen from https://stackoverflow.com/a/45411081/3120446
function scrollNavTo($child) {
    if (!$child.length) {
        return;
    }

    const $parent = $('#nav-div')[0];
    $child = $($child)[0];

    // Where is the parent on page
    const parentRect = $parent.getBoundingClientRect();
    // What can you see?
    const parentViewableArea = {
        height: $parent.clientHeight,
        width: $parent.clientWidth
    };

    // Where is the child
    const childRect = $child.getBoundingClientRect();
    // Is the child viewable?
    const isViewable = (childRect.top >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height);

    // if you can't see the child try to scroll parent
    if (!isViewable) {
        // scroll by offset relative to parent
        $($parent).animate({
            scrollTop: (childRect.top + $parent.scrollTop) - parentRect.top,
        });
    }
}

// let initialNavTop = $navWrapper.get(0).offsetTop,
//     initialContentTop = $content.get(0).offsetTop,
//     isStuck = false,
//     hasEventListener = false;

// function stickyNav() {
//     function moveToTop() {
//         if (window.scrollY > initialNavTop) {
//             $navWrapper.css({
//                 position: 'absolute',
//                 top: window.scrollY,
//                 width: '100%',
//             });

//             if (!isStuck) {
//                 let top = initialContentTop - $content.get(0).offsetTop;
//                 $content.css({ top });
//             }

//             isStuck = true;
//         } else if (isStuck) {
//             isStuck = false;
//             $navWrapper.css({
//                 position: 'sticky',
//                 top: 0,
//                 width: '',
//             });
//             $content.css({
//                 top: '',
//             });
//         }
//     }

//     if (hasEventListener && window.innerWidth >= 768) {
//         hasEventListener = false;
//         isStuck = false;
//         document.removeEventListener('scroll', moveToTop);
//         $navWrapper.css({
//             top: 0,
//             position: 'sticky',
//             width: '',
//         });
//         $content.css({
//             top: '',
//         });
//     }

//     if (window.innerWidth < 768) { // md breakpoint
//         hasEventListener = true;
//         initialNavTop = $navWrapper.get(0).offsetTop;
//         document.addEventListener('scroll', moveToTop);
//         moveToTop();
//     }
// }

function initNav() {
    const $window = $(window);
    setScrollOnToggle();
    $window.on('activate.bs.scrollspy', function(e, { relatedTarget: id }) {
        if (!id) {
            return;
        }

        let $a = $(`#nav a[href$="${id}"]`);
        if ($a.length) {
            scrollNavTo($a);
        }
    });

    // window.addEventListener('resize', stickyNav);
    // window.addEventListener('orientationchange', stickyNav);
    // stickyNav();

    let $lastActive = $nav.find('.active').last();
    if ($lastActive.length === 0) {
        $lastActive = $nav.find('.always-active');
    }

    scrollNavTo($lastActive);
    enableScrollSpy();
}

export default initNav;