import $ from 'jquery';
import moment from 'moment';
import { lastPageVisit } from './last-visit';

class MdSection extends HTMLElement {
    constructor() {
        super();

        this.created = this.getAttribute('created') && moment(this.getAttribute('created') + ' 00-0800');
        this.modified = this.getAttribute('modified') && moment(this.getAttribute('modified') + ' 00-0800');

        const $this = $(this);
        if ($this.parent('p').length) {
            $(this).unwrap();
        }

        const $prev = $this.prev('h1,h2,h3,h4,h5,h6');
        if ($prev.length) {
            $this.detach().appendTo($prev);
        }

        if (lastPageVisit < this.created || this.modified && lastPageVisit < this.modified) {
            $this.addClass('badge badge-primary badge-pill ml-md-2 my-auto py-1 pl-3 pr-2')
                .text(lastPageVisit < this.created ? 'new' : 'updated');

            const href = $this.prevAll('a').attr('href');
            $('#nav').find(`a[href="${href}"]`).addClass('new');
        }

        let timestamp = this.created.toDate().toLocaleDateString();
        if (this.modified) {
            timestamp += ` | Updated: ${this.modified.toDate().toLocaleDateString()}`;
        }

        $('<div>').addClass('timestamp').text(timestamp).appendTo($prev);
    }
}

customElements.define('md-section', MdSection);